<template>
  <div>
    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <!-- Error Alert -->
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>{{ loadingError }}</p>
      </div>
    </b-alert>

    <!-- Table -->
    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(monthly_target)="data">
        <b-input-group :style="{width:'200px'}">
          <b-form-input
            v-model.number="data.value"
            type="number"
            class="border-rounded"
            @input="onTargetChange(data.item, $event)"
          />
          <!-- Show Update Button only when Monthly Target is changed -->
          <b-button
            v-if="data.item.showUpdateButton"
            variant="primary"
            @click="updateTarget(data.item)"
          >
            Update
          </b-button>
          <feather-icon
            v-else
            v-b-tooltip.hover="{boundary:'window'}"
            :style="{margin:'auto'}"
            title="Edit Target"
            icon="Edit3Icon"
            size="20"
            class="cursor-pointer mr-1"
            @click="onTargetChange(data.item, data.value)"
          />
        </b-input-group>
      </template>
    </b-table>

    <!-- Pagination -->
    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => { currentPage = page }"
    />
  </div>
</template>

<script>
import {
  BSpinner,
  BTable,
  BAlert,
  VBTooltip,
  BButton,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    DetailedPagination,
    BButton,
    BInputGroup,
    BFormInput,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      showAddForm: false,
      currentPage: 1,
      perPage: 50,
      sortBy: 'received_date',
      results: [],
      totalItems: 0,
      sortDesc: true,
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'monthly_target', label: 'Monthly-Target', sortable: true },
      ],
      openItemId: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else if (this.filters.salesmans !== undefined) {
        this.fetchItems([...this.filters.salesmans])
      } else {
        this.fetchItems([])
      }
    },
  },
  created() {
    this.fetchItems([])
  },
  methods: {
    // Fetch items based on current filters or salesmans
    fetchItems(ary) {
      this.loading = true
      axios
        .get('/accounts/salesmans_targets')
        .then(res => {
          if (ary === undefined || ary?.length === 0) {
            this.results = res.data.sort((a, b) => {
              if (a.id === 1) return -1 // If `a` has id: 1, place it first
              if (b.id === 1) return 1 // If `b` has id: 1, place it first
              return a.id - b.id // Otherwise, sort in ascending order
            }) || [] // Fallback to an empty array if data is undefined
          } else {
            this.results = res.data.filter(salesman => ary.includes(salesman.id)).sort((a, b) => {
              if (a.id === 1) return -1 // If `a` has id: 1, place it first
              if (b.id === 1) return 1 // If `b` has id: 1, place it first
              return a.id - b.id // Otherwise, sort in ascending order
            }) || []
          }
          this.totalItems = this.results.length
          this.loadingError = null // Reset loading error
          this.loading = false // Set loading to false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    onTargetChange(item, value) {
      // Clone the item to avoid direct modification
      const updatedItem = { ...item }
      // Update the cloned item
      updatedItem.showUpdateButton = true
      updatedItem.monthly_target = +value
      // Find the index of the original item in the results array
      const index = this.results.findIndex(result => result.id === item.id)
      // If the item exists, update it in the results array reactively
      if (index !== -1) {
        this.$set(this.results, index, updatedItem)
      }
    },
    async updateTarget(item) {
      try {
        const updatedItem = { ...item }
        await axios.put('/accounts/salesman_detail', {
          monthly_target: updatedItem.monthly_target,
          name: updatedItem.name,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${updatedItem.name} monthly target updated`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        updatedItem.showUpdateButton = false // Update the cloned object
        const index = this.results.findIndex(result => result.id === updatedItem.id)
        if (index !== -1) {
          this.$set(this.results, index, updatedItem)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Updating Target ',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },

    displayDetails(itemId) {
      this.openItemId = itemId
    },
  },
}
</script>
